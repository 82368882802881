/**
 * Timetastic
 * Absence management software page
 */
import React, { useEffect } from "react"
// import { Link } from "gatsby"
import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage, BgImage } from "gbimage-bridge"
import { BackgroundImage } from "gatsby-background-image"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import BackgroundBlobs from "../components/images/BackgroundBlobs"
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrial from "src/components/freeTrial"
import SignupButton from "src/components/signupButton"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"

// Styles
import "src/styles/templates/how-it-works.scss"
import "src/styles/animations/how-it-works.scss"

// SEO
const title = "Absence management software for hard working teams"
const description =
  "Absence management software for hard working teams. Timetastic is online software that helps you manage and record absence in your business"

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])
  const schema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "How does absence management software work?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "What absence management software does is take all those different pieces of the jigsaw puzzle to arranging time off work — spreadsheets, paper forms, a shared calendar, and maybe back-and-forth on email. And puts it all into one single system that’s always up to date. A single source of truth, full visibility. So when your employees request a day off, all your calendars are instantly updated, the reasons for employee absences are tracked, you can then identify patterns, report on them and spot absence trends if you need. Holiday allowance is tracked and reconciled, all your employees can see how much time off they have left and when they took time off. A whole lot of visibility that you can’t get from spreadsheets.",
        },
      },
      {
        "@type": "Question",
        name: "Will absence management software save me time?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Certainly will. In fact, saving time is the number one main benefit that our customers get from using Timetastic. The first big saving is that your employees request time off in the desktop or mobile app. So they don’t have to pester you with questions or start a back-and-forth on email. Next, you’ll have all those employee holiday requests and bookings instantly show on your calendar, so you can see which employees are off and when. You don’t need to update a spreadsheet or record of how much sickness they have taken or how much holiday allowance someone has left. All that is done for you, instantly. Just think about how much time that saves, and how many interruptions it’ll spare you?",
        },
      },
      {
        "@type": "Question",
        name: "What type of absences does it cover?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Absence management software helps keep a record of any type of employee leave you can think of. Holiday entitlement, sick pay, sickness tracking. You can record maternity, paternity, compassionate and bereavement leave, anything you want. You can create as many different types of employee leave as you like in Timetastic.",
        },
      },
      {
        "@type": "Question",
        name: "Do I need to download or install anything?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Timetastic is cloud based, online software, so you can login form any computer, anywhere. You’ve also got the mobile app, so if you have employees that don’t work at computers or in an office, as long as they have a phone they’ll be able to record their days off. Your business process and holiday calendars will be updated in real time.",
        },
      },
      {
        "@type": "Question",
        name: "Is it easy to implement?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Very easy yes. Most of our customers are setup to monitor absences within an hour or so. It’s a simple case of signing up and working through the settings pages, we’ve also got a <a href='https://help.timetastic.co.uk/hc/en-us/articles/360019495817-Getting-started-how-to-set-up-Timetastic'>quick start guide</a> to take you through the process in less than 10 minutes.",
        },
      },
      {
        "@type": "Question",
        name: "Will my employees like it?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "We’re confident they will. You’ve got <a href='https://www.reviews.co.uk/company-reviews/store/timetastic'>over two and a half thousand reviews to read here</a>, and at the time of writing, 92% of users recommend Timetastic. You can’t go far wrong with numbers like those. 😀",
        },
      },
      {
        "@type": "Question",
        name: "How much does Timetastic cost?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Timetastic is priced on a per user/employee basis and paid on a monthly subscription. As it’s a subscription there’s no long term contracts or tie-ins, you can cancel anytime you like. You can view our <a href='https://timetastic.co.uk/pricing/'>current pricing here</span>. We also have a <a href='https://timetastic.co.uk/discounts/'>good causes discount scheme</a> - 50% off for charities, NHS Trusts, B Corps, 1% for the Planet Members, or Fair Tax Mark companies.",
        },
      },
      {
        "@type": "Question",
        name: "More questions?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Take a look at our <a href='https://help.timetastic.co.uk/hc/en-us'>Help Centre</a> for detailed articles on getting the most for Timetastic or contact our friendly <a href='https://timetastic.co.uk/support/'>Support Team</a>.",
        },
      },
    ],
  }

  return (
    <>
      <SEO title={title} description={description} schemaMarkup={schema} />
      <Layout>
        <Header />
        <FooterCta />
        <main>
          <section
            className="c-section c-how-screenshots is-unanimated"
            id="screenshots"
          >
            <div className="u-inner u-inner--l c-how-screenshots__inner">
              <div>
                <h1 className="h h2 c-how-screenshots__title">
                  Absence management{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    software
                  </span>
                </h1>
                <p>
                  It’s official. Paper forms and spreadsheets aren’t up to the
                  task, you need absence management software that works for your
                  team and their managers. That’s where Timetastic comes in.
                </p>

                <SignupButton
                  buttonCopy="Start free trial"
                  trackCtaLabel="AbsenceManagementHeroSignUp"
                />

                <p className="c-free-trial__no-link">
                  Free for one month - no card required
                </p>
              </div>

              <div className="c-how-screenshots__images is-unanimated">
                <BackgroundBlobs blobType="howHeroBlobs">
                  <StaticImage
                    src="../images/no-spreadsheets-tablet.jpg"
                    width={780}
                    alt="Tablet wallchart"
                    placeholder="blurred"
                    layout="constrained"
                    loading="eager"
                    className="c-how-screenshots__tablet"
                  />
                  <StaticImage
                    src="../images/hero-mobile-booking.jpg"
                    width={197}
                    alt="Mobile booking"
                    placeholder="blurred"
                    layout="constrained"
                    loading="eager"
                    className="c-how-screenshots__mobile"
                  />
                </BackgroundBlobs>
              </div>
              <div className="c-how-screenshots__mobile-s">
                <StaticImage
                  src="../images/no-spreadsheets-mobile-s.jpg"
                  width={359}
                  alt="Mobile calendar"
                  placeholder="blurred"
                  layout="constrained"
                  loading="eager"
                />
              </div>
            </div>
          </section>
          <section className="c-section">
            <div className="u-inner u-inner--l">
              <div className="is-unanimated">
                <h2 className="h h2 u-text-centre-desktop">
                  Instead of messy paperwork,{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    upgrade to:
                  </span>
                </h2>
              </div>

              <div className="c-features">
                <div className="c-feature c-feature--calendars">
                  <div className="is-unanimated">
                    <div className="c-feature--calendars__blob-01">
                      <BackgroundBlobs blobType="howCalendarBlobs">
                        <div className="c-feature-calendars-grid__item">
                          <StaticImage
                            src="../images/calendars-months.jpg"
                            width={619}
                            alt="Calendar months"
                            placeholder="blurred"
                            layout="constrained"
                            className="c-home-grid__annotation c-feature-calendars-grid__image-months c-home-calendars-grid__image"
                          />
                          <StaticImage
                            src="../images/calendars-months-2.jpg"
                            width={627}
                            alt="Calendar months"
                            placeholder="blurred"
                            layout="constrained"
                            className="c-home-grid__annotation c-feature-calendars-grid__image-months-2 c-home-calendars-grid__image"
                          />
                          <StaticImage
                            src="../images/calendars-summary.jpg"
                            width={306}
                            alt="Leave summary"
                            placeholder="blurred"
                            layout="constrained"
                            className="c-home-grid__annotation c-feature-calendars-grid__image-summary c-home-calendars-grid__image"
                          />
                          <StaticImage
                            src="../images/calendars-breakdown.jpg"
                            width={306}
                            alt="Your time off"
                            placeholder="blurred"
                            layout="constrained"
                            className="c-feature-calendars-grid__image c-feature-calendars-grid__image-breakdown"
                          />
                          <StaticImage
                            src="../images/calendars-trends.jpg"
                            width={306}
                            alt="Annual trends"
                            placeholder="blurred"
                            layout="constrained"
                            className="c-feature-calendars-grid__image c-feature-calendars-grid__image-trends"
                          />
                        </div>
                      </BackgroundBlobs>
                    </div>
                  </div>
                  <div className="is-unanimated">
                    <h3 className="h h3">Personal calendars</h3>
                    <p>
                      With leave management software, all your team get their
                      own personal calendar and annual summary. They can see and
                      track their absences and plan their year ahead. Finally,
                      they get a clear picture of when and why they are absent
                      from work.
                    </p>
                    <p>
                      Using Timetastic’s calendar integrations, staff can feed
                      all absences instantly into their regular work calendar –
                      a big step up from those holiday forms and outdated
                      spreadsheets.
                    </p>
                  </div>
                </div>
                <div className="c-feature c-feature__notifications">
                  <div className="is-unanimated">
                    <h3 className="h h3">App & email notifications</h3>
                    <p>
                      Rather than messing with bits of paper and spreadsheets,
                      Timetastic is a leave management system that gives you a
                      single place to record and track absences within your
                      business.
                    </p>
                    <p>
                      Users can request absences from either the desktop or{" "}
                      <a
                        href="https://help.timetastic.co.uk/hc/en-us/categories/200432402-Mobile-Apps"
                        rel="noopener"
                        onClick={() => {
                          trackCta("AbsenceMobileApp")
                        }}
                      >
                        mobile app
                      </a>
                      . Managers can directly approve or decline these via
                      email, and all actions are instantly tracked and
                      summarised on the calendars.
                    </p>
                  </div>
                  <div className="c-feature-notifications-grid__item">
                    <StaticImage
                      src="../images/notifications-request.jpg"
                      width={249}
                      alt="Request notification"
                      placeholder="blurred"
                      layout="constrained"
                      className="c-feature-calendars-grid__image c-feature-calendars-grid__image-request"
                    />
                    <StaticImage
                      src="../images/notifications-approval.jpg"
                      width={249}
                      alt="Approval notification"
                      placeholder="blurred"
                      layout="constrained"
                      className="c-feature-calendars-grid__image c-feature-calendars-grid__image-approval"
                    />
                    <StaticImage
                      src="../images/notifications-request.jpg"
                      width={464}
                      alt="Request notification"
                      placeholder="blurred"
                      layout="constrained"
                      className="c-feature-calendars-grid__image c-feature-calendars-grid__image-request-s"
                    />
                    <StaticImage
                      src="../images/notifications-approval.jpg"
                      width={464}
                      alt="Approval notification"
                      placeholder="blurred"
                      layout="constrained"
                      className="c-feature-calendars-grid__image c-feature-calendars-grid__image-approval-s"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="c-section c-features-gradient__grey">
            <div className="u-inner u-inner--l is-unanimated">
              <h2 className="h h1 u-text-center">
                See clearly, who’s off and when.
              </h2>
              <p className="u-text-center">
                Unlock the power of effective absence management with your new
                interactive wallchart. It gives you instant access to up-to-date
                absence information, offering a new at-a-glance view of who's
                absent and when. Use it to plan better, make sure busy periods
                are covered and avoid awkward clashes.
              </p>

              <div className="c-home-grid__annotation">
                <span className="c-home-grid__annotation--text c-home-grid__annotation--top-left c-home-grid__annotation--hide">
                  Your team
                </span>
                <span className="c-home-grid__annotation--text c-home-grid__annotation--top-right c-home-grid__annotation--hide">
                  Book time off
                </span>
                <StaticImage
                  src="../images/live-wallchart.jpg"
                  width={1200}
                  alt="Wallchart"
                  placeholder="blurred"
                  layout="constrained"
                  className="c-feature-calendars-grid__image c-feature-calendars-grid__image-wallchart"
                />
                <StaticImage
                  src="../images/live-wallchart-mobile.jpg"
                  width={440}
                  alt="Wallchart"
                  placeholder="blurred"
                  layout="constrained"
                  className="c-feature-calendars-grid__image c-feature-calendars-grid__image-wallchart-s"
                />
                <span className="c-home-grid__annotation--text c-home-grid__annotation--bottom-mid-right c-home-grid__annotation--hide">
                  Pending request
                </span>
              </div>
            </div>
          </section>
          <section className="c-section c-features-gradient__green">
            <div className="u-inner u-inner--l is-unanimated ">
              <h2 className="h h2">What makes Timetastic so special?</h2>
              <div className="c-features-seo">
                <div>
                  <p>
                    It’s everything you need, and nothing you don’t.{" "}
                    <strong>
                      For over 10 years, we’ve been building the best absence
                      management software for small businesses.
                    </strong>{" "}
                    If you want absence management software that’s simple and
                    easy to use, you have to double down on making sure each and
                    every feature fights for its place. We all know software
                    apps that are so jam-packed with features they are almost
                    unusable — that’s not Timetastic.
                  </p>
                  <p>
                    <strong>
                      Over 400,000 people have booked time off work using
                      Timetastic.{" "}
                    </strong>
                    They’ve used it, tested it, and fallen in love with it.
                    We’ve seen their pain points, tweaked, simplified, and
                    refined. It’s the gold standard now. You only need to read
                    some of the thousands of positive reviews:
                  </p>
                  <p>
                    Richard nails it: “
                    <a
                      href="https://www.capterra.com/p/146655/Timetastic/reviews/1069884/"
                      rel="nofollow noreferrer noopener"
                      target="_blank"
                    >
                      <i>
                        Timetastic has taken the burden of absence management
                        away.
                      </i>
                    </a>
                    ”
                  </p>
                  <p>
                    And Robin, who puts it so perfectly: “
                    <a
                      href="https://www.capterra.com/p/146655/Timetastic/reviews/1071156/"
                      rel="nofollow noreferrer noopener"
                      target="_blank"
                    >
                      <i>Beautifully simple.</i>
                    </a>
                    ”
                  </p>
                  <h3 className="h h3">Why does Timetastic work?</h3>
                  <p>
                    Timetastic works because it’s a single place for all your
                    absence management-related tasks. It’s where you make your{" "}
                    <Link
                      to="/staff-leave-planner/"
                      onClick={() => {
                        trackCta("AbsenceStaffHoliday")
                      }}
                    >
                      <span>holiday requests</span>
                    </Link>{" "}
                    , where you record sickness absence or any other staff
                    absence. You can identify absence trends, update your
                    calendars and keep track of annual allowances.
                  </p>
                  <p>
                    <strong>
                      All those employee absence-related tasks that you
                      currently use spreadsheets and calendars for, that’s what
                      Timetastic replaces.
                    </strong>
                  </p>
                  <p>
                    And it’s all wrapped up into one simple piece of software -
                    on your desktop and a native mobile app, where everyone has
                    access to the information they need.
                  </p>
                  <h3 className="h h3">
                    Who uses Timetastic for absence management?
                  </h3>
                  <p>
                    <strong>
                      Legal companies, accountants, marketing agencies, software
                      developers, hairdressers, health centres and doctors
                      surgeries, charities, manufacturers, retailers, transport
                      and delivery companies, IT firms, builders and churches.
                    </strong>
                  </p>
                  <p>
                    Tiny micro-enterprises with two entrepreneurs, startups,
                    small to medium businesses and teams at some large
                    multinationals and household names. Every country, every
                    continent.
                  </p>
                  <p>
                    So do it, have a go, and see what all the hype is about for
                    yourself. It’s time to upgrade to a decent absence
                    management system and save yourself from the pain of
                    spreadsheets and constant interruptions.
                  </p>
                  <p>
                    <strong>
                      It's time to get organised, streamline your processes, and
                      gain some visibility in your absence management system,
                      saving yourself a whole heap of time.
                    </strong>
                  </p>
                </div>
                <div className="c-features-seo__media">
                  <a
                    href="https://www.capterra.com/reviews/146655/Timetastic?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
                    rel="nofollow noreferrer noopener"
                    target="_blank"
                    className="capterra"
                  >
                    {" "}
                    <img
                      loading="lazy"
                      border="0"
                      src="https://assets.capterra.com/badge/3fb93b4fa79a460bf121a4febd992c0c.png?v=2103571&p=146655"
                    />
                  </a>
                  <a
                    href="https://www.reviews.co.uk/company-reviews/store/timetastic"
                    rel="nofollow noreferrer noopener"
                    target="_blank"
                    className="reviews-io"
                  >
                    <img
                      loading="lazy"
                      src="https://media.reviews.co.uk/badge/timetastic.png"
                    />
                  </a>
                  <a
                    href="https://uk.trustpilot.com/review/timetastic.co.uk"
                    rel="nofollow noreferrer noopener"
                    target="_blank"
                    className="reviews-io"
                  >
                    <img
                      loading="lazy"
                      src="/images/features/social-proof/trustpilot-badge.png"
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="c-section c-features-gradient__cherry">
            <div className="u-inner u-inner--800 is-unanimated">
              <h2 className="h h2 u-text-centre-desktop">
                A full set of features, so you can{" "}
                <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                  manage time off properly
                </span>
              </h2>
            </div>
            <div className="u-inner u-inner--l">
              <div className="c-feature-points">
                <div className="c-feature-point is-unanimated">
                  <div className="c-feature-point__body">
                    <h3 className="h h3 c-feature-point__title">
                      You’d like a day off?
                    </h3>
                    <p>
                      To manage absence better, you can group your staff into
                      departments, set minimum staffing levels and highlight
                      clashes before approving requests for time off.
                    </p>
                  </div>
                  <StaticImage
                    src="../images/manage-different-teams-white.jpg"
                    width={539}
                    alt="Manage different teams"
                    placeholder="blurred"
                    layout="constrained"
                    className="c-feature-point__image"
                  />
                </div>
                <div className="c-feature-point is-unanimated">
                  <div className="c-feature-point__body">
                    <h3 className="h h3 c-feature-point__title">
                      For what reason?
                    </h3>
                    <p>
                      Handle all types of absence - holidays,{" "}
                      <a
                        href="https://help.timetastic.co.uk/hc/en-us/articles/207627025-Sick-Leave-recording-and-reporting"
                        rel="noopener"
                        onClick={() => {
                          trackCta("AbsenceFeaturesSickLeave")
                        }}
                      >
                        sickness
                      </a>
                      , maternity, paternity, unpaid leave, compassionate.
                      Create your own custom{" "}
                      <a
                        href="https://help.timetastic.co.uk/hc/en-us/articles/202277731-Setting-different-types-of-leave"
                        rel="noopener"
                        onClick={() => {
                          trackCta("AbsenceFeaturesLeaveTypes")
                        }}
                      >
                        leave types
                      </a>{" "}
                      here!
                    </p>
                  </div>
                  <ul className="u-tag-list">
                    <li>Annual Leave</li>
                    <li>Sickness</li>
                    <li>Maternity/Paternity</li>
                    <li>Sabbatical</li>
                    <li>Doctors Appointment</li>
                    <li>Seasonal Shutdown</li>
                    <li>Compassionate</li>
                    <li>Away Day</li>
                    <li>Training</li>
                  </ul>
                </div>
                <div className="c-feature-point is-unanimated">
                  <div className="c-feature-point__body">
                    <h3 className="h h3 c-feature-point__title">
                      It’s a bank holiday
                    </h3>
                    <p>
                      Timetastic comes pre-loaded with{" "}
                      <a
                        href="https://help.timetastic.co.uk/hc/en-us/articles/360000329213-Public-holidays"
                        rel="noopener"
                        onClick={() => {
                          trackCta("AbsenceFeaturesPublicHolidays")
                        }}
                      >
                        public holidays
                      </a>{" "}
                      from over 250 countries and 3,000 regions. All you need do
                      is assign the relevant country to a staff member and their
                      calendar is sorted.
                    </p>
                  </div>
                  <p>
                    <img
                      loading="lazy"
                      alt="Public holidays from 250 countries"
                      className="c-features-grid__imagex"
                      src="/images/features/leave-types/public-holidays-map.png"
                      width="539"
                      height="281"
                    />
                  </p>
                </div>
                <div className="c-feature-point is-unanimated">
                  <div className="c-feature-point__body">
                    <h3 className="h h3 c-feature-point__title">
                      Who’s working?
                    </h3>
                    <p>
                      Create a{" "}
                      <a
                        href="https://help.timetastic.co.uk/hc/en-us/articles/115002485025-Setting-your-users-working-schedule"
                        rel="noopener"
                        onClick={() => {
                          trackCta("AbsenceFeaturesWorkSchedule")
                        }}
                      >
                        work schedule
                      </a>{" "}
                      for each of your team. You’ll be able to see who’s working
                      and when. No more, "Who’s in next Tuesday?", you’ve got a
                      schedule everyone can see and plan from.
                    </p>
                  </div>
                  <StaticImage
                    src="../images/work-schedules-white.jpg"
                    width={539}
                    alt="Work schedules"
                    placeholder="blurred"
                    layout="constrained"
                    className="c-feature-point__image"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="c-section c-features-faq">
            <div className="u-inner u-inner--l is-unanimated">
              <h2 className="h h2">Leave management software FAQs</h2>
              <ul>
                <li>
                  <h3 className="h h4">What is a leave management system?</h3>
                  <p>
                    A leave management system, or absence management system, is
                    an easy-to-use platform that allows employees to view their
                    holiday allowance and request time off – all in one place!
                    It also helps managers approve requests and see who is
                    taking time off, making it easier than ever before to assign
                    resources and staff, especially during busier times of the
                    year.
                  </p>
                </li>
                <li>
                  <h3 className="h h4">
                    How does absence management software work?
                  </h3>
                  <p>
                    What absence management software does is take all those
                    different pieces of the jigsaw puzzle to arrange time off
                    work — spreadsheets, paper forms, a shared calendar, and
                    maybe back-and-forth on email. And puts it all into one
                    single system that’s always up to date. A single source of
                    truth, full visibility.
                  </p>
                  <p>
                    So when your employees request a day off, all your calendars
                    are instantly updated, and the reasons for employee absences
                    are tracked, you can then identify patterns, report on them
                    and spot absence trends if you need to. Holiday allowance is
                    tracked and reconciled, so all your employees can see how
                    much time off they have left and when they’ve taken time
                    off. A whole lot of visibility that you can’t get from
                    spreadsheets!
                  </p>
                </li>
                <li>
                  <h3 className="h h4">
                    Will absence management software save me time?
                  </h3>
                  <p>
                    Certainly will. In fact, saving time is the number one main
                    benefit that our customers get from using Timetastic.
                  </p>
                  <p>
                    The first big saving is that your employees request time off
                    in the desktop or mobile app. So they don’t have to pester
                    you with questions or start a back-and-forth on email.
                  </p>
                  <p>
                    Next, you’ll have all those employee holiday requests and
                    bookings instantly show on your calendar, so you can see
                    which employees are off and when. You don’t need to update a
                    spreadsheet or record of how much sickness they have taken
                    or how much holiday allowance someone has left. All that is
                    done for you, instantly. Just think about how much time that
                    saves, and how many interruptions it’ll spare you?
                  </p>
                </li>
                <li>
                  <h3 className="h h4">What type of absences does it cover?</h3>
                  <p>
                    Absence management software helps keep a record of any type
                    of employee leave you can think of. Holiday entitlement,
                    sick pay, sickness tracking. You can record{" "}
                    <a
                      aria-label="Maternity leave - a short guide to what you're entitled to"
                      href="https://timetastic.co.uk/blog/maternity-leave-what-are-you-entitled-to/"
                      onClick={() => {
                        trackCta("AbsenceFaqMaternity")
                      }}
                    >
                      maternity
                    </a>
                    ,{" "}
                    <a
                      aria-label="A Guide to Paternity Leave in the UK"
                      href="https://timetastic.co.uk/blog/a-guide-to-paternity-leave-in-the-uk/"
                      onClick={() => {
                        trackCta("AbsenceFaqPaternity")
                      }}
                    >
                      paternity
                    </a>
                    ,{" "}
                    <a
                      aria-label="How do compassionate leave and bereavement leave work?"
                      href="https://timetastic.co.uk/blog/compassionate-leave-bereavement-leave/"
                      onClick={() => {
                        trackCta("AbsenceFaqCompassionate")
                      }}
                    >
                      compassionate and bereavement leave
                    </a>
                    , anything you want. You can create as many{" "}
                    <Link
                      to="/features/leave-types/"
                      onClick={() => {
                        trackCta("AbsenceFaqLeaveTypes")
                      }}
                    >
                      different types of employee leave
                    </Link>{" "}
                    as you like in Timetastic.
                  </p>
                </li>
                <li>
                  <h3 className="h h4">
                    Do I need to download or install anything?
                  </h3>
                  <p>
                    Timetastic is cloud based, online absence management
                    software, so you can log in from any computer, anywhere.
                    You’ve also got the mobile app so that employees who don't
                    work at computers or in an office can easily record their
                    days off as long as they have a phone. All your business
                    processes and holiday calendars will be updated in
                    real-time.
                  </p>
                </li>
                <li>
                  <h3 className="h h4">Is it easy to implement?</h3>
                  <p>
                    Very easy, yes. Most of our customers are set up to monitor
                    absences within an hour or so.
                  </p>
                  <p>
                    It’s a simple case of signing up and working through the
                    settings pages, we’ve also got a{" "}
                    <a
                      href="https://help.timetastic.co.uk/hc/en-us/articles/360019495817-Getting-started-how-to-set-up-Timetastic"
                      rel="noopener"
                      onClick={() => {
                        trackCta("AbsenceFaqGuide")
                      }}
                    >
                      quick start guide
                    </a>{" "}
                    to take you through the leave management system process in
                    less than 10 minutes.
                  </p>
                </li>
                <li>
                  <h3 className="h h4">
                    Can it be integrated with other apps?
                  </h3>
                  <p>
                    In short, yes. Absence management software seamlessly
                    integrates with other systems and tools. The{" "}
                    <a
                      href="https://help.timetastic.co.uk/hc/en-us/articles/360015776798-HR-fields"
                      rel="noopener"
                      onClick={() => {
                        trackCta("AbsenceFaqPro")
                      }}
                    >
                      Timetastic Pro
                    </a>{" "}
                    feature gives you even more depth in user profiles, like
                    storing employee contact info, emergency contacts, and
                    payroll IDs. Plus, it syncs up with calendar apps like{" "}
                    <a
                      href="https://help.timetastic.co.uk/hc/en-us/articles/360020971894-Calendar-integration-Google-Calendar"
                      rel="noopener"
                      onClick={() => {
                        trackCta("AbsenceFaqGoogleCalendar")
                      }}
                    >
                      Google Calendar
                    </a>
                    ,{" "}
                    <a
                      href="https://help.timetastic.co.uk/hc/en-us/articles/360020969554-Calendar-Integration-Outlook-Office-365"
                      rel="noopener"
                      onClick={() => {
                        trackCta("AbsenceFaqOutlookOffice365")
                      }}
                    >
                      Outlook and Office 365
                    </a>
                    ,{" "}
                    <Link
                      to="/teams/"
                      onClick={() => {
                        trackCta("AbsenceFaqMicrosoftTeams")
                      }}
                    >
                      Microsoft Teams
                    </Link>
                    ,{" "}
                    <Link
                      to="/slack/"
                      onClick={() => {
                        trackCta("AbsenceFaqSlack")
                      }}
                    >
                      Slack
                    </Link>{" "}
                    and{" "}
                    <a
                      href="https://help.timetastic.co.uk/hc/en-us/articles/212807809-Google-Workspace-introduction"
                      rel="noopener"
                      onClick={() => {
                        trackCta("AbsenceFaqGoogleWorkspace")
                      }}
                    >
                      Google Workspace
                    </a>
                    , making it a breeze to align leave schedules with your
                    team’s existing calendars.
                  </p>
                </li>
                <li>
                  <h3 className="h h4">
                    How can leave management software help with compliance and
                    reporting?
                  </h3>
                  <p>
                    Leave management software typically comes with features that
                    help you follow your policies and regulations regarding
                    employee leave. These include keeping a record of time off
                    work and automated notifications for leave request
                    approvals. Plus, Timetastic gives you reports and analytics
                    on various aspects of leave management, such as usage
                    trends, and other important metrics.
                  </p>
                </li>
                <li>
                  <h3 className="h h4">Will my employees like it?</h3>
                  <p>
                    We’re confident they will. You’ve got{" "}
                    <a
                      href="https://www.reviews.co.uk/company-reviews/store/timetastic"
                      rel="noopener noreferrer nofollow"
                      target="_blank"
                      onClick={() => {
                        trackCta("AbsenceFaqReviews")
                      }}
                    >
                      over two and a half thousand reviews to read here
                    </a>
                    , and at the time of writing, 92% of users recommend
                    Timetastic. You can’t go far wrong with numbers like those.
                    😀
                  </p>
                </li>
                <li>
                  <h3 className="h h4">How much does Timetastic cost?</h3>
                  <p>
                    Timetastic is priced on a per user/employee basis and paid
                    on a monthly subscription. As it’s a subscription there are
                    no long-term contracts or tie-ins and you can cancel anytime
                    you like. View our{" "}
                    <Link
                      to="/pricing/"
                      onClick={() => {
                        trackCta("AbsenceFaqPricing")
                      }}
                    >
                      <span>current pricing here</span>
                    </Link>
                    .
                  </p>
                  <p>
                    We also have a{" "}
                    <Link
                      to="/discounts/"
                      onClick={() => {
                        trackCta("AbsenceFaqDiscounts")
                      }}
                    >
                      <span>good causes discount scheme</span>
                    </Link>{" "}
                    - 50% off for charities, NHS Trusts, B Corps, 1% for the
                    Planet Members, or Fair Tax Mark companies.
                  </p>
                </li>
                <li>
                  <h3 className="h h4">More questions?</h3>
                  <p>
                    Take a look at our{" "}
                    <a
                      href="https://help.timetastic.co.uk/hc/en-us"
                      rel="noopener"
                      onClick={() => {
                        trackCta("AbsenceFaqHelp")
                      }}
                    >
                      Help Centre
                    </a>{" "}
                    for detailed articles on getting the most for Timetastic or
                    contact our friendly{" "}
                    <Link
                      to="/support/"
                      onClick={() => {
                        trackCta("AbsenceFaqSupport")
                      }}
                    >
                      <span>Support Team</span>
                    </Link>
                    .
                  </p>
                </li>
              </ul>
            </div>
          </section>

          <section className="is-unanimated c-section u-text-center c-free-trial c-free-trial--gradient-bottom">
            <div className="u-inner">
              <h2 className="h h2 c-free-trial__title u-text-left-mobile">
                Happier with Timetastic
              </h2>
              <div className="u-inner c-free-trial__copy u-text-left-mobile">
                <Link
                  className="u-em-link"
                  to="/customers/"
                  onClick={() => {
                    trackCta("AbsenceManagementCustomers")
                  }}
                >
                  <span>Read how people like you use Timetastic</span>
                </Link>
                <p>
                  Their stories tell us how they took control of absence
                  management and became healthier, happier places to work
                </p>
                <h2 className="h h4">Want to see how it works?</h2>
                <a
                  className="u-em-link u-text-left-mobile"
                  href="https://timetastic.ewebinar.com/webinar/timetastic-product-demo-6394"
                  target="blank"
                  rel="nofollow noreferrer noopener"
                  onClick={() => {
                    trackCta("eWebinar")
                  }}
                >
                  <span>Watch our 10 minute webinar</span>
                </a>
                {/* <span className="u-text-left-mobile"> or </span>
                <a
                  className="u-em-link u-text-left-mobile"
                  href="https://calendly.com/sarah-timetastic/timetastic-demo"
                  target="blank"
                  rel="nofollow noreferrer noopener"
                  onClick={() => {
                    trackCta("BookDemo")
                  }}
                >
                  <span>book a live demo</span>
                </a> */}
              </div>
            </div>
          </section>

          <section className="c-section u-text-centre u-text-left-mobile c-features-apps">
            <div className="u-inner u-inner--ms is-unanimated">
              <h2 className="h h2">
                <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                  Mobile apps
                </span>{" "}
                too
              </h2>
              <p>
                Your team can make use of the leave management software apps for
                Android and Apple to book an absence. No need to be in the
                office, everything is instantly tracked!
              </p>
            </div>
            <div className="u-inner u-inner--ms">
              <ul className="c-badge-list is-unanimated">
                <li className="c-badge-list__item">
                  <a
                    className="c-badge-list__link"
                    href="https://itunes.apple.com/gb/app/timetastic-nicer-way-to-book/id633985417?mt=8"
                    rel="noreferrer noopener"
                    target="_blank"
                    onClick={() => {
                      trackCta("iOSappclick")
                    }}
                  >
                    <img
                      loading="lazy"
                      alt="Download on App Store"
                      className="c-badge-list__image"
                      height="84"
                      src="/images/badges/app-store.png"
                      width="252"
                    />
                  </a>
                </li>
                <li className="c-badge-list__item">
                  <a
                    className="c-badge-list__link"
                    href="https://play.google.com/store/apps/details?id=com.mediaburst.timetastic&hl=en"
                    rel="noreferrer noopener"
                    target="_blank"
                    onClick={() => {
                      trackCta("Androidappclick")
                    }}
                  >
                    <img
                      loading="lazy"
                      alt="Get it on Google Play"
                      className="c-badge-list__image"
                      height="84"
                      src="/images/badges/google-play.png"
                      width="282"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </section>
          <FreeTrial
            body="Timetastic has been helping people book their holidays since 2012. Doctors, web design agencies, car dealers, online retailers, charities - over 8,000 teams and small businesses just like yours."
            buttonCopy="Start booking holidays now"
            title="200,000+ people use Timetastic"
            trackCtaLabel="AbsenceManagementSignUpFooter"
            imgClass="c-free-trial__user-graph"
          />
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default

export const query = graphql`
  query {
    placeholderImage: file(
      relativePath: { eq: "../images/hero-blobs-landscape.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 100, formats: [AUTO, WEBP])
      }
    }
  }
`
